import { useSetRecoilState } from 'recoil';
import { FeatureOption } from '../../navigation';
import { featureAtom } from '../../../../recoil/atoms/atoms';
import { SHOW_WIP } from '../../../../utils/constants';

export const Intro = () => {
  const setOption = useSetRecoilState(featureAtom);

  return (
    <div className="flex-1 flex flex-col w-full h-auto items-center justify-center text-text_selected">
      <span className="text-2xl font-extrabold">
        Explore Different Features
      </span>
      <span className="text-center mt-4 text-sm text-text_unselected_secondary font-mono">
        Utilize different features we offer to optimize your
        <br />
        experience with Legion AI
      </span>
      <div className="flex flex-row mt-12 w-5/6 justify-center items-stretch space-x-5">
        <div
          className="bg-layer3 hover:bg-layer2 border-outline_unselected border rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8 cursor-pointer"
          onClick={() => {
            setOption(FeatureOption.ConversationalBI);
          }}
        >
          <div className="w-full h-[168.69px] bg-title_conversationalbi">
            <div className='w-full h-full bg-black/[0.65] flex justify-center items-center'>
              <div className="w-20 h-20 bg-conversationalbi bg-cover" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 px-8">
            <div className="flex flex-col w-auto mt-5">
              <span className="text-[21px] font-extrabold text-text_selected py-3">
                askData
              </span>
              <span className="text-text_unselected text-sm">
                Generate SQL queries from natural language and visualize data in real time
              </span>
            </div>
            {/* <hr className="border border-outline_unselected w-full" />
            <div className="w-full flex flex-col gap-2 text-sm">
              <span className="text-text_selected">Output</span>
              <div className="flex flex-row gap-8 flex-wrap">
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-analytics" />
                  Diagram
                </div>
                <div className="text-text_unselected flex gap-x-1 items-center">
                  <div className="w-6 h-6 bg-ad" />
                  Query
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {
          SHOW_WIP ? (
            <div
              className="bg-layer3 hover:bg-layer2 border-outline_unselected border rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8 cursor-pointer"
              onClick={() => {
                setOption(FeatureOption.GENERALQA);
              }}
            >
              <div className="w-full h-[168.69px] bg-title_generalchat">
                <div className='w-full h-full bg-black/[0.65] flex justify-center items-center'>
                  <div className="w-20 h-20 bg-generalchat bg-cover" />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askTextDoc
                  </span>
                  <span className="text-text_unselected text-sm">
                    Use natural language to pull key info or insights from all internal documents connected to this system
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Responses
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Suggestions
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          ) : (
            <div
              className="grayscale bg-layer2 border-outline_unselected border rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8"
            >
              <div className="w-full h-[168.69px] bg-gray-600 flex justify-center items-center">
                <div className="w-20 h-20 bg-generalchat bg-cover" />
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askTextDoc
                  </span>
                  <span className="text-text_unselected text-sm">
                    Use natural language to pull key info or insights from all internal documents connected to this system
                  </span>
                  <span className="text-text_unselected text-sm">
                    [Coming Soon...]
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Responses
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Suggestions
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          )
        }

        {
          SHOW_WIP ? (
            <div
              className="bg-layer3 hover:bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8 cursor-pointer"
              onClick={() => {
                setOption(FeatureOption.DATAENTRY);
              }}
            >
              <div className="w-full h-[168.69px] bg-title_dataentry">
                <div className='w-full h-full bg-black/[0.65] flex justify-center items-center'>
                  <div className="w-20 h-20 bg-dataentry bg-cover" />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askDataSci
                  </span>
                  <span className="text-text_unselected text-sm">
                    Clean your data, analyze it, forecast trends, and uncover hidden insights with one click—becoming a data scientist is as easy as typing in natural language
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Diagram
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Query
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          ) : (
            <div
              className="grayscale bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8"
            >
              <div className="w-full h-[168.69px] bg-gray-600 flex justify-center items-center">
                <div className="w-20 h-20 bg-dataentry bg-cover" />
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askDataSci
                  </span>
                  <span className="text-text_unselected text-sm">
                    Clean your data, analyze it, forecast trends, and uncover hidden insights with one click—becoming a data scientist is as easy as typing in natural language
                  </span>
                  <span className="text-text_unselected text-sm">
                    [Coming Soon...]
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Diagram
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Query
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          )
        }

        {
          SHOW_WIP ? (
            <div
              className="bg-layer3 hover:bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8 cursor-pointer"
              onClick={() => {
                setOption(FeatureOption.WEBSCRAPER);
              }}
            >
              <div className="w-full h-[168.69px] bg-title_webscraper">
                <div className='w-full h-full bg-black/[0.65] flex justify-center items-center'>
                  <div className="w-20 h-20 bg-webscraper bg-cover" />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askWeb
                  </span>
                  <span className="text-text_unselected text-sm">
                    Scape, extract and analyze real-time data from websites using everyday language
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Diagram
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Query
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          ) : (
            <div
              className="grayscale bg-layer2 border-outline_unselected border-2 rounded-2xl overflow-hidden shadow-xl min-w-[200px] basis-1/4 pb-8"
            >
              <div className="w-full h-[168.69px] bg-gray-600 flex justify-center items-center">
                <div className="w-20 h-20 bg-webscraper bg-cover" />
              </div>
              <div className="flex flex-col items-center justify-center gap-5 px-8">
                <div className="flex flex-col w-auto mt-5">
                  <span className="text-[21px] font-extrabold text-text_selected py-3">
                    askWeb
                  </span>
                  <span className="text-text_unselected text-sm">
                    Scape, extract and analyze real-time data from websites using everyday language
                  </span>
                  <span className="text-text_unselected text-sm">
                    [Coming Soon...]
                  </span>
                </div>
                {/* <hr className="border border-outline_unselected w-full" />
              <div className="w-full flex flex-col gap-2 text-sm">
                <span className="text-text_selected">Output</span>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-analytics" />
                    Diagram
                  </div>
                  <div className="text-text_unselected flex gap-x-1 items-center">
                    <div className="w-6 h-6 bg-ad" />
                    Query
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
