import { useSetRecoilState } from 'recoil';
import {
  featureAtom,
  featureExpandAtom,
} from '../../../../recoil/atoms/atoms';
import { FeatureOption } from '../../navigation';
import { useEffect, useState } from 'react';

// TODO: move interface to apis
// This is for displaying only
interface Model {
  name: string;
}

export const WebScraper = () => {
  const setOption = useSetRecoilState(featureAtom);
  const [title, setTitle] = useState('');
  const [isSelectingModel, setIsSelectingModel] = useState(false);
  const setExpand = useSetRecoilState(featureExpandAtom);

  // TODO: move this to recoil
  const [curModel, setCurModel] = useState(null as Model);

  useEffect(() => {
    setExpand(true);
  }, []);

  // TODO: Find a way to get/set all available models
  // This is for displaying only
  const allModels = [{
    name: 'Model1',
  }, {
    name: 'Model2',
  }, {
    name: 'Model3',
  }] as Model[];

  const renderAllModels = () => {
    return allModels.map((model, index) => {
      return (
        <div
          key={index}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer2"
          onClick={() => {
            setIsSelectingModel(false);
            setCurModel(model);
          }}
        >
          <span className="text-sm">{model.name}</span>
        </div>
      );
    });
  };

  const onTitleInput = (event) => {
    setTitle(event.target.value);
  };

  // TODO: Implement this function
  const handleStartConversation = () => { };

  return (
    <div className="flex-1 flex m-auto h-full w-[712px] text-text_unselected">
      <div className="flex flex-col h-auto w-full self-center gap-3">
        <div className="flex items-center gap-3 my-6">
          <div className="w-12 h-12 bg-webscraper bg-cover" />
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-bold text-title_webscraper">
              askWeb
            </span>
            <span className="text-text_unselected">
              Scape, extract and analyze real-time data from websites using everyday language
            </span>
          </div>
        </div>

        <div className="relative space-y-8">
          <div>
            <span className="pl-1 font-medium text-sm">Title</span>
            <input
              className="mt-2 h-12 w-full rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_selected flex items-center cursor-pointer"
              placeholder="Enter conversation title"
              value={title}
              onChange={onTitleInput}
            />
          </div>
          <div>
            <span className="pl-1 font-medium text-sm">AI</span>
            <div
              className="my-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
              onClick={() => setIsSelectingModel(!isSelectingModel)}
            >
              <span className={`${curModel && 'text-text_unselected'}`}>
                {curModel ? curModel.name : 'Select a language model'}
              </span>
            </div>
            {
              isSelectingModel && <div className="absolute mt-0 w-full h-auto z-10 max-h-36 bg-active_hover rounded">
                <div className="py-2 max-h-36 overflow-auto">
                  {renderAllModels()}
                </div>
              </div>
            }
          </div>
          <div className={'mt-2 h-10 w-full flex justify-end'}>
            <div className="flex items-center">
              <div
                className="ml-6 w-20 h-full flex items-center justify-center border border-outline_unselected rounded cursor-pointer"
                onClick={() => setOption(FeatureOption.NONE)}
              >
                <span className="font-semibold">Close</span>
              </div>
              <div
                className="ml-6 w-40 h-full flex items-center justify-center border border-outline_unselected rounded bg-layer2 cursor-pointer"
                onClick={handleStartConversation}
              >
                <span className="text-text_unselected font-semibold">
                  Start Conversation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
