import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addDatasetModalAtom,
  allDatasetsAtom,
  curConversationAtom,
  curDatasetAtom,
  featureAtom,
  featureExpandAtom,
  selectedFilesAtom,
} from '../../../../recoil/atoms/atoms';
import { useEffect, useState } from 'react';
import {
  getAllDatasets,
  reloadDataset,
} from '../../../../apis/datasources/apis';
import { FeatureOption } from '../../navigation';
import {
  ConversationType,
  CreateConversationDto,
} from '../../../../apis/conversations/interfaces';
import { startNewConversation } from '../../../../apis/conversations/apis';
import Swal from 'sweetalert2';
import { FileUploader } from '../../datasources/components/file_uploader';
import { uploadFiles } from '../../../../apis/upload/apis';
import { UploadFileRequestDto } from '../../../../apis/upload/interfaces';
import { DatasetType } from '../../../../apis/datasources/interfaces';
import { fetchConversationWithId } from '../../../../utils/conversation_utils';

export const GeneralChat = () => {
  const setOption = useSetRecoilState(featureAtom);
  const setCurConversation = useSetRecoilState(curConversationAtom);
  const [curDataset, setCurDataset] = useRecoilState(curDatasetAtom);
  const [title, setTitle] = useState('');
  const [isSelectingDocument, setIsSelectingDocument] = useState(false);
  const setAddDatasetModal = useSetRecoilState(addDatasetModalAtom);
  const [allDatasets, setAllDatasets] = useRecoilState(allDatasetsAtom);
  const [isSingleUseUpload, setIsSingleUseUpload] = useState(false);

  const setExpand = useSetRecoilState(featureExpandAtom);
  const setFeatureOption = useSetRecoilState(featureAtom);
  const selectedFiles = useRecoilValue(selectedFilesAtom);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  useEffect(() => {
    getAllDatasets().then(setAllDatasets);
    setExpand(true);
  }, []);

  const onTitleInput = (event) => {
    setTitle(event.target.value);
  };

  const renderAllDatasets = () => {
    return allDatasets.map((dataset) => {
      return (
        <div
          key={dataset.id}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer2"
          onClick={() => {
            setIsSelectingDocument(false);
            setCurDataset(dataset);
            setSelectedDatasets((prevDatasets) => {
              if (!prevDatasets.includes(dataset)) {
                return [...prevDatasets, dataset];
              } else {
                return prevDatasets;
              }
            });
          }}
        >
          <span className="text-sm">{dataset.name}</span>
        </div>
      );
    });
  };

  const handleStartConversation = async () => {
    if (!isSingleUseUpload && selectedDatasets.length === 0) {
      Swal.fire('Error', "Document can't be empty", 'error');
      return;
    }

    if (isSingleUseUpload && selectedFiles.length === 0) {
      Swal.fire('Error', "File can't be empty", 'error');
      return;
    }

    if (!title) {
      Swal.fire('Error', "Title can't be empty", 'error');
      return;
    }

    const createConversationDto: CreateConversationDto = {
      conversationName: title,
      conversationType: ConversationType.GENERAL,

      qa: {
        datasetIds: selectedDatasets.map((dataset) => dataset.id),
      }
    };

    if (isSingleUseUpload) {
      const formData = new FormData();

      // TODO improve this logic
      let fileType = '';
      for (const file of selectedFiles) {
        formData.append('files', file);
        if (!fileType) {
          fileType = file.type;
        } else if (fileType !== file.type) {
          Swal.fire('Error', 'File type is not consistent', 'error');
          return;
        }
      }
      const uploadReq = new UploadFileRequestDto();
      if (fileType.includes('pdf')) {
        uploadReq.filetype = DatasetType.PDF;
      } else {
        Swal.fire('Error', 'Only support PDF', 'error');
        return;
      }
      formData.append('metadata', JSON.stringify(uploadReq));

      const datasets = await uploadFiles(formData);
      // it depends on how should we proceed the multiple documents, so keep single document for the moment.
      await Promise.all(datasets.map((dataset) => reloadDataset(dataset.id)));
      createConversationDto.qa.datasetIds = datasets.map((dataset) => dataset.id);
    }

    startNewConversation(createConversationDto)
      .then(convId => {
        fetchConversationWithId(
          convId,
          3,
          300,
          (conv) => {
            setCurConversation(conv);
            setFeatureOption(FeatureOption.NONE);
            setExpand(false);
          },
          (err) => {
            Swal.fire('Error', JSON.stringify(err), 'error');
          }
        );
      })
      .catch(err => {
        Swal.fire('Error', JSON.stringify(err.data));
      });
  };

  return (
    <div className="flex-1 flex m-auto h-full text-text_unselected">
      <div className="flex flex-col h-auto w-full self-center gap-3">
        <div className="flex items-center m-auto gap-3 my-6">
          <div className="w-12 h-12 bg-generalchat bg-cover" />
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-bold text-title_generalchat">askTextDoc</span>
            <span className="text-text_unselected">
              Use natural language to pull key info or insights from all internal documents connected to this system
            </span>
          </div>
        </div>

        <div>
          <span className="pl-1 font-medium text-sm">Title</span>
          <input
            className="mt-2 h-12 w-full rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_selected flex items-center cursor-pointer"
            placeholder="Enter conversation title"
            value={title}
            onChange={onTitleInput}
          />
        </div>

        <div className="relative">
          {allDatasets.length > 0 && (
            <div className="flex justify-between mt-3">
              <span className="pl-1 font-medium text-sm self-end">Data</span>
              <div className="flex gap-2">
                <div
                  className={`flex items-center justify-center h-8 w-36 ${isSingleUseUpload ? 'bg-border_unselected' : 'bg-active'
                  } rounded-lg cursor-pointer`}
                  onClick={() => {
                    setIsSingleUseUpload(false);
                  }}
                >
                  <span
                    className={`${isSingleUseUpload
                      ? 'text-text_unselected'
                      : 'text-text_selected'
                    } text-sm text-semibold`}
                  >
                    My Documents
                  </span>
                </div>
                <div
                  className={`flex items-center justify-center h-8 w-36 ${isSingleUseUpload ? 'bg-active' : 'bg-border_unselected'
                  } rounded-lg cursor-pointer`}
                  onClick={() => {
                    setIsSingleUseUpload(true);
                  }}
                >
                  <span
                    className={`${isSingleUseUpload
                      ? 'text-text_selected'
                      : 'text-text_unselected'
                    } text-sm text-semibold`}
                  >
                    File Upload
                  </span>
                </div>
              </div>
            </div>
          )}
          {isSingleUseUpload ? (
            <FileUploader />
          ) : (
            <>
              {allDatasets.length === 0 ? (
                <div
                  className="mb-3 flex gap-1 items-center justify-center h-10 w-44 bg-active rounded cursor-pointer"
                  onClick={() => {
                    setCurDataset(null);
                    setAddDatasetModal(true);
                  }}
                >
                  <div className="w-6 h-6 bg-blueplus" />
                  <span className=" text-text_selected text-sm text-semibold">
                    Add New Document
                  </span>
                </div>
              ) : (
                <>
                  <div
                    className="my-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
                    onClick={() => setIsSelectingDocument(!isSelectingDocument)}
                  >
                    <span className={`${curDataset && 'text-text_unselected'}`}>
                      {curDataset ? curDataset.name : 'Select a Document'}
                    </span>
                  </div>
                  {isSelectingDocument && (
                    <div className="absolute mt-2 w-full h-auto max-h-36 bg-active_hover rounded">
                      <div className="py-2 max-h-36 overflow-auto">
                        {renderAllDatasets()}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="bg-layer2 h-36 rounded flex flex-col overflow-auto gap-y-1">
                {selectedDatasets.length !== 0 ? (
                  <div>
                    {selectedDatasets.map((dts) => {
                      return (
                        <div
                          key={dts.id}
                          className="flex gap-x-6 p-7 justify-between items-center w-full h-12 rounded hover:bg-active text-text_unselected hover:text-text_selected bg-outline_unselected"
                        >
                          <p className="w-1/2 text-text_selected font-bold">
                            {dts.name}
                          </p>
                          <div className="w-1/3">
                            <div className="w-24 truncate">
                              {JSON.stringify(dts.connJson)}
                            </div>
                          </div>
                          <p className="w-1/6">{dts.type}</p>
                          <div
                            onClick={() => {
                              setCurDataset(null),
                              setSelectedDatasets((cur) =>
                                cur.filter((dt) => dt !== dts)
                              );
                            }}
                          >
                            <div className="w-6 h-6 dark:bg-close bg-lightclose cursor-pointer" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span className="text-text_unselected m-auto">
                    No data chosen
                  </span>
                )}
              </div>
            </>
          )}
        </div>

        <div className={'mt-2 h-10 w-full flex justify-end'}>
          <div className="flex items-center">
            <div
              className="ml-6 w-20 h-full flex items-center justify-center border border-outline_unselected rounded cursor-pointer"
              onClick={() => setOption(FeatureOption.NONE)}
            >
              <span className="font-semibold">Close</span>
            </div>
            <div
              className="ml-6 w-40 h-full flex items-center justify-center border border-outline_unselected rounded bg-layer2 cursor-pointer"
              onClick={handleStartConversation}
            >
              <span className="text-text_unselected font-semibold">
                Start Conversation
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
