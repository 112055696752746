import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addDatabaseRedashAtom,
  allDatabasesAtom,
  allDatasetsAtom,
  curConversationAtom,
  curDatabaseAtom,
  curDatasetAtom,
  featureAtom,
  featureExpandAtom,
  selectedFilesAtom,
} from '../../../../recoil/atoms/atoms';
import { FeatureOption } from '../../navigation';
import Swal from 'sweetalert2';
import { FileUploader } from '../../datasources/components/file_uploader';
import {
  getAllDatabases,
  getAllDatasets,
  uploadDataset,
} from '../../../../apis/datasources/apis';
import { useEffect, useState } from 'react';
import {
  ConversationType,
  CreateConversationDto,
} from '../../../../apis/conversations/interfaces';
import { startNewConversation } from '../../../../apis/conversations/apis';
import {
  DatasetType,
  UploadDataset,
} from '../../../../apis/datasources/interfaces';
import { fetchConversationWithId } from '../../../../utils/conversation_utils';

export const DataEnty = () => {
  const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const [curDataset, setCurDataset] = useRecoilState(curDatasetAtom);
  const setAddDatabaseModal = useSetRecoilState(addDatabaseRedashAtom);
  const setOption = useSetRecoilState(featureAtom);
  const selectedFiles = useRecoilValue(selectedFilesAtom);
  const [isSelectingDatabase, setIsSelectingDatabase] = useState(false);
  const [isSelectingDataset, setIsSelectingDataset] = useState(false);
  const [allDatabases, setAllDatabases] = useRecoilState(allDatabasesAtom);
  const [allDatasets, setAllDatasets] = useRecoilState(allDatasetsAtom);
  const setCurConversation = useSetRecoilState(curConversationAtom);

  const setExpand = useSetRecoilState(featureExpandAtom);
  const setFeatureOption = useSetRecoilState(featureAtom);

  useEffect(() => {
    getAllDatabases().then(setAllDatabases);
    getAllDatasets().then(setAllDatasets);
    setExpand(true);
  }, []);

  const renderAllDatabases = () => {
    return allDatabases.map((database) => {
      return (
        <div
          key={database.id}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer2"
          onClick={() => {
            setIsSelectingDatabase(false);
            setCurDatabase(database);
          }}
        >
          <span className="text-sm">{database.name}</span>
        </div>
      );
    });
  };

  const [title, setTitle] = useState('');
  const onTitleInput = (event) => {
    setTitle(event.target.value);
  };


  const renderAllDatasets = () => {
    return allDatasets.map((database) => {
      return (
        <div
          key={database.id}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer2"
          onClick={() => {
            setIsSelectingDataset(false);
            setCurDataset(database);
          }}
        >
          <span className="text-sm">{database.name}</span>
        </div>
      );
    });
  };

  const handleStartConversation = async () => {
    // if (!curDatabase) {
    //   Swal.fire('Error', "Document can't be empty", 'error');
    //   return;
    // }

    // if (selectedFiles.length === 0) {
    //   Swal.fire('Error', "File can't be empty", 'error');
    //   return;
    // }

    if (selectedFiles.length > 0 && curDataset) {
      Swal.fire('Error', "Can't choose a dataset and upload a file together", 'error');
      return;
    }
    if (selectedFiles.length === 0 && !curDataset) {
      Swal.fire('Error', 'Have to either choose a dataset or upload a file', 'error');
      return;
    }


    const createConversationDto: CreateConversationDto = {
      conversationName: title,
      conversationType: ConversationType.DATAENTRY,
      dataentry: {},
    };

    createConversationDto.dataentry.databaseId = curDatabase?.id ?? null;

    // Upload a single file
    if (selectedFiles.length > 0) {
      const dataset: UploadDataset = {
        files: [selectedFiles[0]], // Wrap selectedFiles[0] inside an array
        type: DatasetType.S3File,
      };

      const datasetUploadRes = await uploadDataset(dataset);
      createConversationDto.dataentry.datasetIds = [datasetUploadRes[0].id];
    } else if (curDataset) {
      createConversationDto.dataentry.datasetIds = [curDataset.id];
    }

    startNewConversation(createConversationDto)
      .then(convId => {
        fetchConversationWithId(
          convId,
          3,
          300,
          (conv) => {
            conv.connectedDataset = createConversationDto.dataentry.datasetIds[0];
            setCurConversation(conv);
            setFeatureOption(FeatureOption.NONE);
            setExpand(false);
          },
          (err) => {
            Swal.fire('Error', JSON.stringify(err), 'error');
          }
        );
      })
      .catch(err => {
        Swal.fire('Error', JSON.stringify(err.data));
      });

    // startNewConversation(createConversationDto)
    //   .then((convId) => {
    //     // add a small pause to avoid race condition
    //     setTimeout(() => {}, 300);
    //     return getConversation(convId);
    //   })
    //   .then((conv) => {
    //     const connectedDataset = {};
    //     setCurConversation(conv);
    //     setFeatureOption(FeatureOption.NONE);
    //     setExpand(false);
    //   })
    //   .catch((err) => {
    //     Swal.fire('Error', JSON.stringify(err.data));
    //   });
  };

  return (
    <div className="flex-1 flex m-auto h-full w-[712px] text-text_unselected">
      <div className="flex flex-col h-auto w-full self-center gap-3">
        <div className="flex items-center m-auto gap-3 my-6">
          <div className="shrink-0 w-12 h-12 bg-dataentry bg-cover bg-center" />
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-bold text-title_dataentry">
              askDataSci
            </span>
            <span className="text-text_unselected">
              Clean your data, analyze it, forecast trends, and uncover hidden insights with one click—becoming a data scientist is as easy as typing in natural language
            </span>
          </div>
        </div>

        <div className="relative">

          <span className="pl-1 font-medium text-sm">Title</span>
          <input
            className="my-2 h-12 w-full rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_selected flex items-center cursor-pointer"
            placeholder="Enter conversation title"
            value={title}
            onChange={onTitleInput}
          />
          {allDatabases.length === 0 ? (
            <div
              className="flex gap-1 items-center justify-center h-10 w-44 bg-active rounded cursor-pointer"
              onClick={() => {
                setAddDatabaseModal(true);
              }}
            >
              <div className="w-6 h-6 bg-blueplus" />
              <span className=" text-text_selected text-sm text-semibold">
                Add New Database
              </span>
            </div>
          ) : (
            <>
              <span className="pl-1 font-medium text-sm">(Optional) Choose Database</span>
              <div
                className="my-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
                onClick={() => setIsSelectingDatabase(!isSelectingDatabase)}
              >
                <span className={`${curDatabase && 'text-text_unselected'}`}>
                  {curDatabase ? curDatabase.name : 'Select a Database to merge into'}
                </span>
              </div>
              {isSelectingDatabase && (
                <div className="absolute mt-0 w-full h-auto z-10 max-h-36 bg-active_hover rounded">
                  <div className="py-2 max-h-36 overflow-auto">
                    {renderAllDatabases()}
                  </div>
                </div>
              )}
            </>
          )}

          {allDatabases.length > 0 && (
            <>
              <span className="pl-1 font-medium text-sm">Choose Existing Dataset</span>
              <div
                className="my-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
                onClick={() => setIsSelectingDataset(!isSelectingDataset)}
              >
                <span className={`${curDataset && 'text-text_unselected'}`}>
                  {curDataset ? curDataset.name : 'Select a Dataset'}
                </span>
              </div>
              {isSelectingDataset && (
                <div className="absolute mt-0 w-full h-auto z-10 max-h-36 bg-active_hover rounded">
                  <div className="py-2 max-h-36 overflow-auto">
                    {renderAllDatasets()}
                  </div>
                </div>
              )}
            </>
          )}
          <FileUploader />
          <div className={'mt-2 h-10 w-full flex justify-end'}>
            <div className="flex items-center">
              <div
                className="ml-6 w-20 h-full flex items-center justify-center border border-outline_unselected rounded cursor-pointer"
                onClick={() => setOption(FeatureOption.NONE)}
              >
                <span className="font-semibold">Close</span>
              </div>
              <div
                className="ml-6 w-40 h-full flex items-center justify-center border border-outline_unselected rounded bg-layer2 cursor-pointer"
                onClick={handleStartConversation}
              >
                <span className="text-text_unselected font-semibold">
                  Start Conversation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
